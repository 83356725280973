<template>
  <v-form ref="edicaoProcessoForm" @submit.prevent="salvarProcesso">
    <ProcessoForm v-model="processo" />
    <div class="d-flex justify-end">
      <v-card-actions>
        <v-btn color="primary" type="submit">
          Salvar
        </v-btn>
      </v-card-actions>
    </div>
  </v-form>
</template>

<script>
import ProcessoForm from "@/components/base/forms/ProcessoForm";
import {mapActions, mapState, mapMutations} from "vuex";
import processoService from "@/services/processo.service";
import _ from 'lodash';

export default {
  name: "Editar",

  components: {
    ProcessoForm
  },

  data() {
    return {
      processo: {},
    }
  },

  computed: {
    ...mapState("processoSelecionado", ["processoSelecionado"]),
  },

  created() {
    this.processo = _.cloneDeep(this.processoSelecionado);
  },

  methods: {
    ...mapActions("avisos", ["exibirAviso"]),
    ...mapMutations("processoSelecionado", ['setProcessoSelecionado']),

    async salvarProcesso() {
      if (this.$refs.edicaoProcessoForm.validate()) {
        let loader = this.$loading.show();

        if (this.processo.especificacoes) {
          if (!this.processo.especificacoes.find(especificacao => especificacao.codigo === 'PNE')) {
            this.processo.prefixoNumeroEnem = null;
          }

          if (!this.processo.especificacoes.find(especificacao => especificacao.codigo === 'UO')) {
            this.processo.anoIngressoUniversidadeOrigem = null;
          }
        }

        await processoService.atualizarProcesso(this.processo)
            .then((response) => {
              this.setProcessoSelecionado(response.data);
              this.exibirAviso({
                mensagem: "Processo atualizado com sucesso!",
                tipo: "success"
              });
            })
            .catch(error => {
                  let mensagem = "Não foi possível atualizar o processo";
                  if (error.response.status === 409) {
                    mensagem += ": " + error.response.data.msg;
                  }
                  this.exibirAviso({
                    mensagem,
                    tipo: "error"
                  });
                }
            );

        loader.hide();
      }
    }
  }
}
</script>

<style scoped></style>
